<template>
  <v-container class="my-5" style="height: 100%">
    <v-row justify="center" align="center" style="height: 100%">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          width="450px"
          style="margin-left: auto; margin-right: auto" />
      </v-col>

      <v-col xl="3" lg="5" md="6" sm="9" xs="12">
        <validation-observer v-slot="{ handleSubmit, failed }">
          <v-form
            lazy-validation
            @submit.prevent="handleSubmit(submitForm)"
            style="display: flex; flex-direction: column">
            <validation-provider rules="required|max:200" v-slot="{ errors }" name="username">
              <v-text-field
                autofocus
                ref="usernameInput"
                v-model="username"
                clearable
                :label="$t('login.username')"
                @animationstart="checkAnimation"
                :persistent-placeholder="autofilled"
                :error-messages="errors"></v-text-field>
            </validation-provider>

            <validation-provider rules="required|max:200" v-slot="{ errors }" name="password">
              <v-text-field
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="togglePassword"
                clearable
                :label="$t('login.password')"
                :persistent-placeholder="autofilled"
                :error-messages="errors"></v-text-field>
            </validation-provider>

            <v-checkbox v-model="rememberMe" :label="$t('login.rememberMe')"></v-checkbox>

            <button-submit :failed="failed" :loading="loading" buttonText="buttons.login"></button-submit>

            <v-btn class="mt-5" @click="redirectToGoogleSignIn" className="google-button" color="default">
              <div class="google-icon-wrapper mr-5">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48">
                  <g>
                    <path
                      fill="#EA4335"
                      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                    <path
                      fill="#4285F4"
                      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                    <path
                      fill="#FBBC05"
                      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                    <path
                      fill="#34A853"
                      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                    <path fill="none" d="M0 0h48v48H0z"></path>
                  </g>
                </svg>
              </div>
              {{ $t('login.signInWithGoogle') }}
            </v-btn>

            <p class="mt-5">
              {{ $t('login.noAccount') }}
              <router-link
                to="/registration"
                class="text-decoration-none text-uppercase"
                style="font-weight: bold; color: blue">
                {{ $t('login.registerHere') }}
              </router-link>
            </p>

            <p class="mt-2">
              {{ $t('login.forgetPassword') }}
              <router-link
                to="/change-password"
                class="text-decoration-none text-uppercase"
                style="font-weight: bold; color: blue">
                {{ $t('login.registerHere') }}
              </router-link>
            </p>

            <v-dialog v-model="errorModalValue" max-width="500">
              <v-card>
                <v-card-text>
                  <br />
                  {{ $t('login.passwordChangeError') }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" dark @click="errorModalValue = false">
                    {{ $t('buttons.ok') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import state from '../store/index';
import router, { rolesMap } from '../router/index';
import store from '@/store';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'LoginView',
  props: ['passwordChangeError'],
  components: { ButtonSubmit },
  data: () => ({
    username: '',
    password: '',
    rememberMe: true,
    user: null,
    loading: false,
    errorModalValue: null,
    showPassword: false,
    autofilled: false,
  }),
  created() {
    this.errorModalValue = this.passwordChangeError;
  },
  methods: {
    submitForm() {
      this.loading = true;
      this.$store
        .dispatch('auth/login', { username: this.username, password: this.password, remember_me: this.rememberMe })
        .then((res) => {
          if (res.success) {
            this.$tawkMessenger?.hideWidget();

            if (res.user.role_id !== 3) {
              res.user.role_id = parseInt(res.user.role.id);
              state.dispatch('auth/setUser', res.user);
              state.dispatch('auth/setRole', res.user);
              state.dispatch('auth/setAuthToken', res.access_token);
              state.dispatch('auth/setAuthenticated', true);
              state.dispatch('auth/setSubscriptionTypeId', res.subscription_type_id);
              state.dispatch('auth/setOrganisationType', res.user.organisation.type);

              if (res.user.language) {
                localStorage.setItem('currentLanguage', res.user.language);
                if (res.user.role_id !== 11) {
                  this.$store.dispatch('language/changeLanguage', res.user.language);
                }
              }

              res.user.role_id !== 1 && state.dispatch('auth/setSubscription', res.subscription);
              res.user.role_id !== 1 && state.dispatch('auth/setCustomer', res.customer);

              const userRole = rolesMap.find((e) => e.id === parseInt(store.getters['auth/role']));
              if (this.$deviceToken) {
                // eslint-disable-next-line no-console
                this.submitToken(this.$deviceToken).catch(() => console.log('error saving token'));
              }
              router.push({ name: userRole.redirect }).catch((err) => {
                // Ignore the vuex err regarding  navigating to the page they are already on.
                if (
                  err.name !== 'NavigationDuplicated' &&
                  !err.message.includes('Avoided redundant navigation to current location')
                ) {
                  // But print any other errors to the console
                  // eslint-disable-next-line no-console
                  console.log(err);
                }
              });
              localStorage.setItem('loggedIn', true);
              if (this.rememberMe === true) {
                localStorage.setItem('LimoExpressToken', res.access_token);
                localStorage.setItem('LimoExpressUser', JSON.stringify(res.user));
                localStorage.setItem('saveToken', true);
                localStorage.setItem('customer', JSON.stringify(res.customer));
                localStorage.setItem('subscription', JSON.stringify(res.subscription));
                localStorage.setItem('subscription_type_id', res.subscription_type_id);
                localStorage.setItem('organisation_type', res.user.organisation.type);
              }
            }

            if (res.logged_out_from_other_device === true) {
              this.$store.dispatch('showSnackbar', {
                text: i18n.t('snackbar.logoutFromAnotherDevice'),
                color: '#ffeb3b',
                textColor: '#000',
              });
            }
          } else {
            if (res.code === 'LE-001') {
              this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.loginFailed'), color: 'red' });
            } else if (res.code) {
              this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.organisationSuspended'), color: 'red' });
            } else {
              this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
            }
          }
        })
        .catch((e) => {
          if (e.response.data.code === 'LE-001') {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.loginFailed'), color: 'red' });
          } else if (e.response.data.code) {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.organisationSuspended'), color: 'red' });
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async submitToken(token) {
      await this.$store.dispatch('firebaseToken/submitToken', { token });
    },

    redirectToGoogleSignIn() {
      window.location.href = process.env.VUE_APP_BACKEND_URL + 'auth/google';
    },

    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    checkAnimation(e) {
      if (e.animationName == 'onAutoFillStart') {
        this.autofilled = true;
      } else if (e.animationName == 'onAutoFillCancel') {
        this.autofilled = false;
      }
    },
  },
};
</script>

<style>
:-webkit-autofill {
  animation-name: onAutoFillStart;
}
:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}
@keyframes onAutoFillStart {
  from {
  }
  to {
  }
}
@keyframes onAutoFillCancel {
  from {
  }
  to {
  }
}
</style>
